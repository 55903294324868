import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "BLOOMPY – MAKING TRAVEL SOCIAL",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:54:05.000Z",
  "url": "/2018/02/bloompy-making-travel-social/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Client:`}</strong>{` Bloompy`}</p>
    <p><strong parentName="p">{`Date:`}</strong>{` 09/15`}</p>
    <p><strong parentName="p">{`Info:`}</strong>{` Social Travel App Amazing project for creating a `}{`“`}{`social travel`}{`”`}{` website.`}</p>
    <div>
  Embedded iFrame
    </div>
    <p>{`The application offers all the features of social services: feeds, posts, commenting, group messaging, private messaging, image sharing and more.`}</p>
    <p>{`I created the full product from the backend and apis, to the responsive website, and mobile apps.`}</p>
    <p>{`Amazing experience, for launching a great product from the ground up, especially with the fact I had no experience with mobile apps.`}</p>
    <p>{`The technology stack was:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`backend:`}</strong>{` php`}</li>
      <li parentName="ul"><strong parentName="li">{`DB:`}</strong>{` mysql, redis`}</li>
      <li parentName="ul"><strong parentName="li">{`Queue:`}</strong>{` beanstalkd`}</li>
      <li parentName="ul"><strong parentName="li">{`frontend:`}</strong>{` angular, javascript, jquery, html, css3`}</li>
      <li parentName="ul"><strong parentName="li">{`mobile:`}</strong>{` ionic framework, phonegapp`}</li>
      <li parentName="ul"><strong parentName="li">{`build system:`}</strong>{` gulp`}</li>
      <li parentName="ul"><strong parentName="li">{`automation & deployment:`}</strong>{` Ansible`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      